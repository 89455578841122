<template>
  <div class="surveyCategoryForm-container">
    <form-panel ref="formPanel"
                v-bind="submitConfig"
                :form="form"
                :submitBefore="submitBefore"
                @update="update">
      <col2-detail>
        <col2-block title="基本信息">
          <el-form-item label="分类名称"
                        :rules="[{ required: true, message: '请输入分类名称', trigger: 'blur' }]"
                        prop="value">
            <v-input v-model="form.value"
                     placeholder="请输入名称"
                     :width="width"
                     :maxlength="10" />
          </el-form-item>
          <el-form-item label="状态"
                        :rules="[{ required: true, message: '请选择状态', trigger: 'change' }]"
                        prop="codeFlag">
            <v-select v-model="form.codeFlag"
                      :options="newStatusOps"
                      :width="width" />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { saveURL, getQueryURL } from './api'
import { statusOps } from './map'
import { Col2Detail, Col2Block } from 'components/bussiness'

export default {
  name: 'surveyCategoryForm',
  components: {
    Col2Detail,
    Col2Block
  },
  data () {
    return {
      width: 182,
      newStatusOps: [],
      form: {
        id: undefined,
        value: '',
        codeFlag: '1'
      },
      submitConfig: {
        queryUrl: '',
        submitUrl: saveURL,
        submitMethod: 'post'
      }
    }
  },
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.$setBreadcrumb('编辑')
      this.submitConfig.queryUrl = getQueryURL(id)
      this.submitConfig.submitMethod = 'put'
      this.$refs.formPanel.getData()
    } else {
      this.$setBreadcrumb('新增')
    }
    // 状态
    this.newStatusOps = [...statusOps]
    this.newStatusOps.shift()
  },
  methods: {
    update (data) {
      data.img = [{
        url: data.img
      }]
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
    },
    submitBefore (data) {
      return true
    }
  }
}
</script>
