import { generateMapByOpts } from 'common/utils'

const statusOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '正常',
    value: '1'
  }, {
    text: '关闭',
    value: '0'
  }
]

// 性别
const statusMap = generateMapByOpts(statusOps)

export {
  statusOps,
  statusMap
}
