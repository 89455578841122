// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}questionnaire/category`
// 获取详情
const getQueryURL = (id) => { return `${API_CONFIG.butlerBaseURL}questionnaire/category/${id}` }
// 保存分类
const saveURL = `${API_CONFIG.butlerBaseURL}questionnaire/category`

export {
  getListURL,
  getQueryURL,
  saveURL
}
