var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "surveyCategoryForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基本信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "分类名称",
                        rules: [
                          {
                            required: true,
                            message: "请输入分类名称",
                            trigger: "blur",
                          },
                        ],
                        prop: "value",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入名称",
                          width: _vm.width,
                          maxlength: 10,
                        },
                        model: {
                          value: _vm.form.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "value", $$v)
                          },
                          expression: "form.value",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "状态",
                        rules: [
                          {
                            required: true,
                            message: "请选择状态",
                            trigger: "change",
                          },
                        ],
                        prop: "codeFlag",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.newStatusOps, width: _vm.width },
                        model: {
                          value: _vm.form.codeFlag,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "codeFlag", $$v)
                          },
                          expression: "form.codeFlag",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }